.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
  }
  .my-custom-scrollbar2 {
    position: relative;
    height: 500px;
    margin-left:auto;
    padding: 20px;
    overflow: auto;
    }
  .table-wrapper-scroll-y {
  display: block;
  }
  ::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(239, 235, 235);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#fff;
    -webkit-box-shadow: inset 0 0 6px rgba(90, 89, 89, 0.7);
}
  
  .table{
    --bs-table-striped-bg: none !important
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#messageSend{
  top:5vh !important
}
.card {
  border: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.AdressInput {
  background-color:#80808038  !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-border {
  border: none !important;
}

.cell-border {
  border: 1px solid black !important
}
.bordered td, .bordered th {
  border: none !important;
}