@import "../../variables.scss";

svc-tab-designer {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-tab-designer {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  position: static;
  background: var(--ctr-surface-background-color, $background-dim);

  .svc-text {
    color: var(--ctr-surface-placeholder-text-title-color, $foreground);
  }
}

.svc-tab-designer--with-place-holder .svc-tab-designer_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.svc-tab-designer--with-place-holder .svc-designer-header {
  min-width: calcSize(84);
}

.svc-content-wrapper {
  width: 100%;
  display: flex;
}

.svc-designer-header .svc-surface-header {
  background-color: transparent;
  padding-inline-start: var(--lbr-header-padding-left, calcSize(3));
  padding-inline-end: var(--ctr-survey-header-padding-right-logo-placeholder, calcSize(1));
  padding-block-start: var(--lbr-header-padding-top, calcSize(3));
  padding-block-end: var(--lbr-page-header-padding-bottom, calcSize(3));
}

.svc-surface-header {
  .sd-title {
    .sv-string-editor {
      &[aria-placeholder]:empty:before {
        color: var(--ctr-survey-header-text-title-color-placeholder, $foreground-light);
      }
    }
  }

  .sd-description {
    color: var(--ctr-survey-header-text-description-color-placeholder, #909090);
  }
}

.svc-tab-designer {
  .svc-designer-header {
    border-bottom: 2px solid transparent;
    position: relative;
  }

  .svc-tab-designer_content {
    width: 100%;
  }

  .sd-title {
    display: flex;
  }

  .sd-container-modern {
    min-width: calcSize(70);
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;

    &.sd-container-modern--static {
      max-width: calcSize(84);
    }

    &.sd-container-modern--responsive {
      max-width: initial;
      padding: 0 calcSize(2);
    }
  }

  .sd-question.sd-question--image {
    width: 100%;
  }

  .sd-progress,
  .sd-progress__bar {
    background-color: transparent;
  }
}

.svc-designer__placeholder-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: calcSize(2);
  box-sizing: border-box;
  text-align: center;
  position: relative;

  .sd-page {
    display: none;
  }
}

.svc-designer-placeholder-page {
  .svc-page__footer {
    // width: calcSize(33);
    margin: auto;
  }

  .svc-row--ghost {
    display: none;
  }

  .svc-element__add-new-question {
    padding: var(--ctr-button-padding-vertical, calcSize(2)) var(--ctr-button-padding-horizontal-contextual-button, calcSize(10));

    .svc-element__question-type-selector {
      padding: var(--ctr-button-contextual-button-padding-top, calcSize(1)) var(--ctr-button-contextual-button-padding-right, calcSize(1)) var(--ctr-button-contextual-button-padding-bottom, calcSize(1)) var(--ctr-button-contextual-button-padding-left, calcSize(1));

      margin: var(--ctr-button-contextual-button-margin-vertical, calcSize(1)) var(--ctr-button-contextual-button-margin-horizontal, calcSize(1));

      border-radius: var(--ctr-button-contextual-button-corner-radius, calcSize(0.5));
    }

    .svc-element__question-type-selector-icon {
      width: var(--ctr-button-contextual-button-icon-width, calcSize(3));
      height: var(--ctr-button-contextual-button-icon-height, calcSize(3));

      .sv-svg-icon {
        width: var(--ctr-button-contextual-button-icon-width, calcSize(3));
        height: var(--ctr-button-contextual-button-icon-height, calcSize(3));
      }

      use {
        fill: var(--ctr-button-contextual-button-icon-color, $foreground-light);
      }
    }

    .svc-add-new-item-button__text {
      @include ctrDefaultBoldFont;
      color: var(--ctr-button-text-color, $primary);
      text-align: center;
    }
  }
}

.svc-tab-designer__toolbar {
  position: absolute;
  bottom: calcSize(0);
  right: 0;
}

.svc-creator__toolbox--right,
[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .svc-tab-designer__toolbar {
    position: absolute;
    bottom: calcSize(0);
    right: unset;
    left: 0;
  }
}

.svc-tab-designer__surface-toolbar {
  padding: var(--ctr-surface-toolbar-padding-top, calcSize(1.5)) var(--ctr-surface-toolbar-padding-right, calcSize(1.5)) var(--ctr-surface-toolbar-padding-bottom, calcSize(1.5)) var(--ctr-surface-toolbar-padding-left, calcSize(1.5));
  gap: var(--ctr-surface-toolbar-gap, calcSize(1));
  flex-direction: column;

  .sv-action-bar-separator {
    background: var(--ctr-separator-color, $border);
    height: var(--ctr-separator-width, 1px);
    width: var(--ctr-page-navigator-button-icon-width, calcSize(1));
    padding: 0px var(--ctr-separator-margin-vertical-small, calcSize(1));
    margin-bottom: var(--ctr-surface-toolbar-gap, calcSize(1));
    margin-right: 0;
  }

  .sv-action__content {
    flex-direction: column;
  }
}
