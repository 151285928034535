@import "../../variables.scss";

.svc-tab-translation {
  width: 100%;
  height: 100%;
  background-color: var(--ctr-surface-background-color, $background-dim);
}

.svc-translation-tab {
  /* disable library transition animations */
  --sjs-transition-duration: 0ms;
}

.svc-translation-tab--empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  gap: 0px;
}


.st-properties {
  background-color: var(--ctr-property-grid-background-color, $background-dim);
  flex-grow: 1;
  padding-right: calcSize(4);
  padding-left: calcSize(4);
  padding-top: calcSize(2);

  .spg-table {
    .spg-table__cell:first-of-type {
      width: calcSize(5);
    }

    .spg-table__question-wrapper {
      @include ctrDefaultFont;
      padding: calcSize(1.5) calcSize(1);
      color: var(--ctr-checkbox-text-color, $foreground-dim);

      .spg-checkbox {
        margin: 0;
      }

      .spg-selectbase__label {
        gap: 0;
      }
    }

    .spg-checkbox--disabled {
      .spg-checkbox__caption {
        color: var(--ctr-checkbox-text-color-disabled, $foreground-light);
      }
    
      .spg-checkbox__rectangle,
      .spg-checkbox__rectangle:hover {
        border: var(--ctr-checkbox-border-width, 1px) solid var(--ctr-checkbox-border-color-disabled, rgba(0, 0, 0, 0.16));
        background: var(--ctr-checkbox-background-color-disabled, #f3f3f3);
      }
    }
  }

  .spg-matrixdynamic__dragged-row {
    @include ctrDefaultFont;
    color: var(--ctr-checkbox-text-color, $foreground-dim);

    .spg-table__cell:nth-child(3) {
      flex: 1;
    }
  }
}

.st-content {
  display: flex;
  height: 100%;
}

.st-strings {
  overflow-y: auto;
  overflow-x: hidden;
}

.st-strings-wrapper {
  flex-grow: 1;
}

.st-property-panel {
  flex-shrink: 0;
  flex-grow: 0;
  width: 450px;
  border-left: 1px solid $border;
}

.st-property-panel .spg-panel__content .spg-row {
  margin-top: 0;
}

.st-property-panel .spg-panel__content .spg-row:first-child {
  margin-top: calcSize(2);
}

.st-strings-header {
  .st-table__cell {
    background-color: var(--ctr-string-table-header-background-color, $background-dim);
    height: auto;
  }
}

.st-root-modern {
  width: 100%;
}

.st-title.st-panel__title,
.st-table__cell.st-table__cell--header {
  @include ctrSmallBoldFont;
  text-align: left;
}

.st-table__cell.st-table__cell--header {
  color: var(--ctr-string-table-header-text-color, $foreground-light);
}

.st-title.st-panel__title {
  margin: 0;
  color: var(--ctr-string-table-group-header-text-color, $foreground-light);
  background-color: var(--ctr-string-table-group-header-background-color, $background-dim-light);
  border-bottom: 1px solid var(--ctr-string-table-row-border-color, $border-light);
  padding: var(--ctr-string-table-row-padding-top, #{calcSize(1)})
    var(--ctr-string-table-row-padding-right, #{calcSize(3)}) var(--ctr-string-table-row-padding-bottom, #{calcSize(1)})
    var(--ctr-string-table-row-padding-left, #{calcSize(3)});
}

.st-table {
  tr {
    display: flex;
    align-items: stretch;
  }
}

.st-table__cell {
  display: block;
  flex: 1 1;
  box-sizing: border-box;
  background-color: var(--ctr-string-table-row-background-color, $background);
  border-bottom: 1px solid var(--ctr-string-table-row-border-color, $border-light);
  padding: calcSize(1) 0;
}

td.st-table__cell:first-of-type {
  @include ctrDefaultBoldFont;
  color: var(--ctr-string-table-row-text-color-title, $foreground);
  max-width: 300px;
  padding-right: calcSize(3);

  span {
    display: inline-block;
    padding-left: calcSize(3);
  }
}

.st-panel-indent .st-table__cell:first-of-type {
  span {
    padding-left: calcSize(6);
  }
}

.st-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.st-comment {
  @include ctrDefaultFont;
  display: block;
  width: calc(100% - 3 * #{$base-unit});
  border: unset;
  outline: none;
  background-color: var(--ctr-string-table-row-background-color, $background);
  color: var(--ctr-string-table-row-text-color-title, $foreground);
  resize: none;
  padding: 0;
}

.sd-translation-line-skeleton {
  min-height: calcSize(5);
  background-color: $background-dim;
}

.st-navigation-btn {
  @include ctrDefaultBoldFont;
  padding-left: calcSize(1.5);
  padding-right: calcSize(2);
  white-space: nowrap;

  .sd-action__icon {
    margin-left: 0;
  }
}

.st-navigation-btn.sd-action--icon {
  padding: calcSize(1);

  &:not(.sd-action--pressed) {

    &:hover,
    &:focus {
      background-color: $primary-light;
    }
  }

  svg use {
    fill: $primary;
  }
}

.svc-translation-machine {
  margin-right: auto;
}

.st-translation-machine-from {
  margin-right: auto;
}

.st-translation-machine-from__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.st-translation-machine-from__container--disabled {
  gap: calcSize(0.5);
}

.st-translation-machine-from__btn {
  flex-direction: row-reverse;
  gap: calcSize(1);
  padding-left: calcSize(2);
  padding-right: calcSize(1.5);
  white-space: nowrap;

  .sd-action__icon {
    width: calcSize(2);
    height: calcSize(2);
    margin-left: 0;
  }

  &.sd-action--pressed:not(.sd-action--active) {
    background-color: $primary-light;
  }

  &:disabled {
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
  }
}

.st-translation-machine-from__btn:disabled .sd-action__title,
.st-translation-machine-from__title {
  @include ctrDefaultFont;
  color: $foreground-dim-light;
}

.st-body__footer {
  padding: calcSize(2);
  padding-left: calcSize(3);
  background: $background;
  border-bottom: 1px solid var(--ctr-string-table-row-border-color, $border-light);
}

.st-translation-dialog .st-strings-wrapper {
  border-radius: 4px;
  box-shadow: $shadow-small;
  overflow: hidden;
}

.st-container-modern {
  .sv-components-column--expandable {
    width: auto;
  }
}

.st-body {
  @include disableLibraryAnimations;
}