body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Averta-Light', sans-serif;
}

h1,h2,h3,h4,h5,h6,p,li,a,label,span,strong,input,button,select,td,th,textarea, input::placeholder {
  font-family: 'Averta-Light', sans-serif !important;
  font-weight: 600 !important;
}
.btn-primary {
  background-color: #EE295D !important;
  border-color: #EE295D !important;
}
.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem #ee295d3b !important;
}
.btn-danger {
  background-color: red !important;
  border-color: red !important;
}
.btn-danger:focus {
  box-shadow: 0 0 0 0.25rem #ff000029 !important;
}
.navHeader a {
  text-decoration: none;
  color: #333;
}
.sideBar .active {
  color: #EE295D;
}
input:-webkit-autofill, input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.container-fluid {
  max-width: 90%;
}
.HorizontalAppointment-title, .VerticalAppointment-title {
  white-space: pre-wrap !important;
}
.rdt tfoot td, .rdtTime .rdtSwitch {
  background-color: #EE295D !important;
  color: #fff !important;
  height: 40px;
}
.rdtActive {
  background-color: #EE295D !important;
}
.calendarIcon {
  position: relative;
}
.rdt input {
  background-color: transparent !important;
}
.rdtPicker {
  border-radius: 6px;
}
.calendarIcon i {
  position: absolute;
  right: 1.5rem;
  margin-top: 2.5rem;
  color: #8898aa;
}
.Demo-title {
  font-size: 14px !important;
  white-space: pre-wrap !important;
  margin-top: -18px;
}
.css-uhzg2n .Demo-title::first-line {
  font-size: 18px !important;
  line-height: 2;
}
.HorizontalAppointment-title::first-line, .VerticalAppointment-title::first-line {
  font-weight: bold;
  font-size: 14px;
}
.navHeader {
  background-color: #fff;
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 2px solid #eeee;
}

.navHeader ul {
  text-align: right;
  position: relative;
  top: 10px;
}
.navHeader .col-md-2 img {
  height: 50px;
}
.uiBreadcrumbs {
  display: flex;
  justify-content: space-between;
}
.patient_table thead {
  position: sticky;
  top: 0;
}
.PInfo span {
  padding-right: 2rem;
}
#cusMessages {
  position: fixed;
  background-color: bisque;
  padding: 16px 4rem 16px 1rem;
  right: -99rem;
  top: 1.5rem;
  transition: 0.4s;
  z-index: 9999;
  border-radius: 4px;
}
div#cusMessages i {
  color: green;
  margin-right: 8px;
  font-size: 22px;
  position: relative;
  top: 2px;
}
.PInfo i {
  color: #EE295D;
}
.navHeader ul li {
  display: inline-block;
  padding: 0 14px;
}
.navHeader li img {
  height: 22px;
  position: relative;
  left: -4px;
  top: -2px;
}
.patient_table, .appointment_table {
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  margin-bottom: 4rem;
}

#patient_table{
  height: inherit !important;
}

.patient_table table {
  overflow-x: auto;
  display: block;
  height: 80vh;
}
.patient_table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}
.patient_table thead {
  background-color: whitesmoke;
  border-bottom: 2px solid rgba(0, 0, 0, .05);
  border-top: 2px solid rgba(0, 0, 0, .05);
}
.uiBreadcrumbs ul {
  padding: 0px;
}
.uiBreadcrumbs svg {
  height: 22px;
  width: 22px;
  transform: rotate(-90deg);
  margin: 0 4px;
}
.uiBreadcrumbs a {
  color: #333;
  text-decoration: none;
}
.uiBreadcrumbs li {
  display: inline-block;
  padding-right: 4px;
}
.uiBreadcrumbs svg {
  margin-top: -3px;
}
.patient_table a, .appointment_table a {
  text-decoration: none;
}
.tableHeader {
  padding: 16px 0px;
  border-bottom: 1px solid #dee2e6;
}
.patient_table .tableHeader, .appointment_table .tableHeader {
  padding: 16px 18px;
}
.clinicForm.patient_table table {
  height: auto;
}
.patient_table th, .patient_table td {
  white-space: nowrap;
  padding: 12px 18px;
  font-weight: 100;
  font-size: 14px;
  width: 100vh;
}
.patientList tbody td {
  padding: 4px 18px;
}
.patient_table th{
  font-weight: 400;
  color: #8898aa;
}
.tableHeader h5 {
  margin-top: 1rem;
}
.navHeader a {
  text-decoration: none;
  color: #333;
}
.Experience-table table {
  height: auto;
}
.Experience-table th {
  width: 100vh;
}
label {
  color: #8898aa;
  margin-bottom: 6px;
  font-size: 14px;
}
.form-control {
  border-color: #dee2e6;
  color: #8898aa !important;
  margin-bottom: 1rem;
  font-size: 14px;
}
.info_form .tableHeader {
  padding: 1rem 0;
  margin-bottom: 2rem;
}
.Experience-table {
  margin: 2rem 0;
}

.info_form form {
  padding: 0px 18px 4rem;
}
.PatientMenu ul {
  margin: 0;
  padding: 0;
}
.PatientMenu li {
  display: inline-block;
  width: 16.6666666667%;
}
.PatientMenu a {
  color: #EE295D;
  background-color: #fff;
  box-shadow: 0 4px 6px #ee295d2b;
  font-size: 14px;
  text-align: center;
  padding: 14px;
  transition: 0.6s;
  display: block;
}
.pactive a {
  background-color: #EE295D !important;
}
.PatientMenu a:hover {
  color: #fff;
}
.PatientMenu a:hover  {
  background-color: #EE295D;
}
.pactive a {
  color: #fff;
}
.patient_photos .tableHeader .btn {
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  padding: 8px 18px;
  margin: 16px 10px 16px 0px;
}
.clinicForm {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 0px 18px 2rem;
  margin-bottom: 3rem;
}
.clinicForm .tableHeader {
  margin-bottom: 2rem;
}
.Clinic {
  margin-top: 4rem;
}
.clinicCard {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 24px;
  border-radius: 6px;
}
.clinicCard .row {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.patientList input[type="search"], .patientList select {
  border: 1px solid #dee2e6;
  color: #8898aa !important;
  font-size: 14px;
  padding: 4px 8px;
  background-color: #fff;
  outline: none;
}
.patientList select {
  appearance: none;
  padding-right: 44px !important;
}
.sideBar {
  padding-left: 6px;
}
.sideBar h5 {
  padding: 2rem 0;
}
.sideBar ul {
  margin: 0;
  padding: 0;
}
.sideBar li {
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
}
.sideBar a {
  color: #333;
  padding: 14px 0;
  display: inline-block;
  font-size: 14px;
}
.sideBar img {
  height: 12px;
  margin-right: 6px;
  margin-top: -4px;
}
div#panel1a-header {
  padding: 0;
}
.css-1ps6pg7-MuiPaper-root {
  box-shadow: none !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
.inventory .light {
  border: 2px solid #EE295D;
  color: #EE295D;
}
.product_search {
  max-width: 60%;
  float: left;
  margin-right: 10px;
  height: 40px;
}
.navHeader .active a {
  color:#EE295D;
}
.navHeader .active:after {
  content: "";
  height: 2px;
  width: 100px;
  background: #EE295D;
  display: block;
  left: 50%;
  margin-left: -50px;
  position: relative;
  bottom: -8px;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  padding-bottom: 6rem;
}
.loader img {
  height: 150px;
}

.reactPagination ul {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 1rem;
}
.reactPagination li {
  list-style: none;
}
.reactPagination a {
  padding: 6px 12px;
  display: inline-block;
  border: 1px solid #e1e1e8;
  color: #333;
}
.reactPagination .previous {
  margin-right: 16px;
}
.reactPagination .next {
  margin-left: 16px;
}
li.selected a, .reactPagination a:hover {
  background-color: #EE295D;
  color: #fff !important;
  border-color: #EE295D;
}
.imageCompare .container-fluid::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
table::-webkit-scrollbar { 
  height: 6px;
  width: 6px;
}
table::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 50px;
}
.imageCompare .container-fluid {
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
}
table {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #c1c1c1;
}
.caseBox {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  padding: 24px 14px;
}
.caseSection .col-md-3 img {
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}
.caseSection .patient_table {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.caseBox .btn {
  height: 36px;
  width: 100px;
  margin-left: -8px;
}
.row.caseSection {
  padding: 2rem 1rem 2rem;
}
.imageCompare {
  position: fixed;
  top: 0;
  background: #333333bd;
  width: 300px;
  height: 300px;
  left: 0;
  padding-left: 5%; 
  padding-right: 5%;
  display: none;
  z-index: 100;
}
.imageCompare .container-fluid {
  background-color: #fff;
  padding: 0rem 2rem 2rem;
  height: 90vh;
  margin-top: 5vh;
  overflow-y: scroll;
  border-radius: 6px;
}
.imageCompare .col-md-12 {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9999;
}
.comLeft {
  margin-bottom: 1rem;
}   
.comLeft img {
  width: 25%;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.comRight {
  position: sticky;
  top: 6rem;
}
#comRight{
  height: inherit !important;
  margin-right: 50px;
}
.comRight img {
  height: 70vh;
  width: 48%;
  margin-right: 0;
  object-fit: cover;
  background-color: #f3f5fb;
}
.imageCompare h5 {
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  padding: 1rem 0 1rem;
  margin-bottom: 3rem;
 }
.imageCompare h5 span {
  float: right;
  border: 2px solid #000;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  text-align: center;
  margin-top: -2px;
  cursor: pointer;
}
.caretOrder span {
  font-size: 12px;
  position: absolute;
  top: 18px;
  cursor: pointer;
  margin-left: 4px;
}
span.asce {
  top: 18px;
  display: none;
}
.caretOrder th {
  position: relative;
}
.patientList tbody img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  object-fit: cover;
}
.patient_table tbody a {
  color: #333;
}
.patient_table tbody tr:hover {
  background-color: #EE295D;
  color: #fff;
  cursor: pointer;
}
.patient_table tbody tr:hover a {
  color: #fff;
}
.UpdateAppointment, .createReward {
  padding: 2rem 18px;
}
.dis-medication-s tr:hover
{
  background-color: #f2f2f2 !important; 
  color: #fff;
  cursor: pointer;
}
.dis-medication-s tr 
{
  border: 1px solid #cccccc;
}
.ecrf-medication-table{
 height: auto !important; 
}
.ecrf-medication-table tr:nth-child(even) {background-color: #f2f2f2;}
.ecrf-medication-table tr:nth-child(odd) {background-color: #ffffff !important;}
.ecrf-medication-table thead tr th{
background-color: #f2f2f2 !important;
}
/* .conditional{
  display: none;
} */
.fillers {
  display: none;
}
.form-control {
  font-size: 14px !important;
}
.Nmin:after {
  content: "Minutes" !important;
  top: 34px !important;
  right: 4rem;
  position: relative;
}
#messageSend {
  width: 500px;
  background-color: whitesmoke;
  padding: 2rem;
  position: fixed;
  top: 14vh;
  border-radius: 8px;
  left: 50%;
  margin-left: -250px;
  display: none;
  box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%);
}
.loginHeader {
  padding-top: 1rem;
}
.loginHeader li {
  display: inline-block;
  cursor: pointer;
  color: #c19c9d;
  padding: 1.5rem 1rem;
}
.loginHeader ul {
  text-align: right;
}
.loginBox {
  max-width: 500px;
  background-color: #fff;
  padding: 2rem;
  text-align: center;
  border-radius: 6px;
  margin: 0 auto;
  margin-top:5%;
}
.loginBox input {
  border: navajowhite;
  display: block;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid red;
  padding: 8px;
  outline: none;
}
.loginBox h1 {
  color: #EE295D;
  margin-bottom: 1rem;
}
.loginBox button {
  background-color: #EE295D;
  color: #fff;
  border: 1px solid #EE295D;
  padding: 4px 2rem;
  border-radius: 6px;
  margin-top: 1rem;
}

.loginFooter {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: #C09C9D;
  padding: 3rem 0;
}
.loginFooter img {
  height: 70px;
}
.loginFooter ul {
  margin-bottom: 0;
}
.loginFooter li {
  color: #fff;
  font-size: 14px;
  margin-top: 4px;
}
.footerIcon a {
  color: #ee4a5d;
  background-color: #fff;
  border-radius: 6px;
  width: 45px;
  height: 45px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 24px;
  padding-top: 7px;
  margin-top: 1rem;
}
.bgImg img:nth-child(1) {
  height: 250px;
  position: absolute;
  margin-top: -296px;
  left: 0;
}
.bgImg img:nth-child(2) {
  height: 250px;
  position: absolute;
  margin-top: -296px;
  right: 0;
}
.forgotPassword {
  display: block;
  text-align: left;
  margin-top: 1.5rem;
  text-decoration: none;
  color: #0d6efd;
  margin-bottom: 0;
}
.documentShow {
  margin-top: 2rem !important;
}
.documentShow span {
  background-color: #3c4d69;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 4px;
}
.documentShow h6 {
  margin-bottom: 0px;
}

.image-zoom {
  /* position: static; */
  top: 50%;
  left: 0px;
  width: 421px;
  height: 500px;
  overflow: hidden;
  margin-right: 20px;
}

.image-zoom img {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 500px;
  object-fit: contain;

}

.controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-top: 20px;
  transform: translate(300%, 70%);
  z-index:1;

}

.controls button {
  margin-right: 10px;

}

.btn-loading {
  width: 120px;
  height: 36px;
  padding: 0;
}


.imageCompare {
  /* position: fixed; */
  top: 0;
  background: #333333bd;
  width: 100%;
  height: 100%;
  left: 0;
  margin-left: 1%;
  margin-right: 1%;
  display: none;
}
.imageCompare .container-fluid {
  background-color: #fff;
  padding: 0rem 2rem 2rem;
  height: 90vh;
  margin-top: 5vh;
  overflow-y: scroll;
  border-radius: 6px;
}

.imageCompare.image-pop-u
{
  position: fixed !important; 
  top: 15%!important;
  background: rgba(0, 0, 0, 0.5) !important;
  width: 36%;
  height: 80%;
  left: 0;
  margin-left: 30%;
  display: none;
  border: 2px solid #333333;
  border-radius: 10px;
}
.imageCompare.image-pop-u .comRight img
{ 
  background:none !important;
  padding: 0px !important;
  margin: 0px;
  background: rgba(0, 0, 0, 1);
}

.imageCompare.image-pop-u h5 span {
  float: right;
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  height: 30px;
  width: 40px;
  border-radius: 50px;
  text-align: center;
  margin-top: -2px;
  cursor: pointer;
}

.buttonsz-u button:hover
{
min-width: 125px;
border:1px solid #f7d1d1 !important;
}

.popup-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media screen and (max-width: 1480px) {
  .image-pop-u .image-zoom {
    width: 35vw;
    position: static;
    min-height: 52vh;
    overflow: hidden;
    left: 0px;
    width: 421px;
    height: 500px;
    overflow: hidden;
    margin-right: 20px;
  }

}
@media screen {
  .buttonsz-u button
  {
  min-width: 125px;
  border:1px solid red
  }
  }


@media screen and (max-width: 520px) {
  .image-pop-u .image-zoom {
    position: relative;
    width: 30vw;
    height: 55vh;
    overflow: hidden;
  }

}

.table-bordered.table-sm {
  border-collapse: collapse;
}
.block_table table {
  overflow-x: auto;
  display: block;
  height: 40vh;
}

.btn.disabled-cursor {
  cursor: pointer !important;
  pointer-events: auto !important;
}

.btn.disabled-cursor[disabled]:hover::before {
  /* content: attr(title); */
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
}

.fixImage, .replaceImage {
  border: 2px solid #ccc;
  margin: 0px 50px 54px 0px;
  color: #fff;
  padding: 2px 25px 18px 25px;
  z-index: 9999 !important;
}

.tableHeader .custom_pagination{
  padding: 16px 0px;
  border-bottom: 1px solid #dee2e6;
  margin-top: -150px;

}
.modal-90w {
  max-width: 90%;
}

.modal-90w .modal-body {
  max-height: calc(100vh - 200px); 
  overflow-y: auto;
}
.icon-with-text .text {
  margin-left: 10px;
}
.watermark {
  position: absolute;
  top: 60%;
  left: 45%;
  transform: translate(-50%, -50%) rotate(-30deg);
  z-index: -1;
  opacity: 0.2; 
  font-weight: bold;
  font-size: 30px; 
}

div.btn.btn-primary.sv-btn.svd-toolbar-button.svd_save_btn .svd-toolbar-button__title span {
  display: none !important;
}

div.btn.btn-primary.sv-btn.svd-toolbar-button.svd_save_btn .svd-toolbar-button__title::before {
  content: 'Save eCRF Block' !important; 
}

.svd-page.svd-light-border-color.svd-page-add .svd-page-name {
  display: none; 
}

.svd-page.svd-light-border-color.svd-page-add::before {
  content: 'Add eCRF Block'; 
  display: inline-block; 
}


div.svd_block_settings_btn .svd-toolbar-button__title span {
  display: none !important;
}

div.svd_block_settings_btn .svd-toolbar-button__title::before {
  content: 'Block Settings';
  display: inline-block;
}

.btn.btn-primary.sv-btn.svd-toolbar-button[title="Open survey settings"] {
  display: none;
}

.svd-toolbar-dropdown__select option:last-child {
  display: none;
}

.svd-toolbar-dropdown__select option:first-child {
  display: none;

}

.svd-tabs > li:nth-child(2) .svd-tab-text:before {
  content: "New Test Survey Title";
}



/* .svd-toolbar-dropdown__select {
  display: none !important;
} */
/* .svd-toolbar-dropdown__label {
  display: none !important;
} */

/* NewEcrf.css */
.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Add more styling as needed */



/* Add this CSS to your component file or import it from an external stylesheet */

.cardContainer {
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formTitle {
  margin-bottom: 20px;
}

/* Style for individual form fields */
.textField {
  margin-bottom: 15px;
}

.checkbox {
  margin-top: 15px;
}
.data-grid-size {
  height: 950px;
}

@media only screen and (max-width: 2000px) and (min-width: 1224px) {
  .data-grid-size {
    height: 950px;
  }
}