.spg-input {
  @include ctrDefaultFont;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: var(--ctr-editor-background-color, $background);
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: var(--ctr-editor-corner-radius);
  box-shadow: inset 0 0 0 var(--ctr-editor-border-width, 1px) var(--ctr-editor-border-color, $border-inside);
  transition: box-shadow $creator-transition-duration;

  padding-top: calc(var(--ctr-editor-padding-top, 4px) + var(--ctr-editor-content-margin-top, 8px));
  padding-right: calc(var(--ctr-editor-padding-right, 4px) + var(--ctr-editor-content-margin-right, 12px));
  padding-bottom: calc(var(--ctr-editor-padding-bottom, 4px) + var(--ctr-editor-content-margin-bottom, 8px));
  padding-left: calc(var(--ctr-editor-padding-left, 4px) + var(--ctr-editor-content-margin-left, 12px));
  outline: none;
  color: var(--ctr-editor-content-text-color, $foreground);
}

.spg-input::placeholder {
  color: var(--ctr-editor-content-text-color-placeholder, $foreground-light);
}

.spg-input:focus:not(:disabled),
.spg-input.spg-dropdown:focus:not(:disabled),
.spg-input.spg-dropdown:focus-within:not(:disabled),
.spg-input-container:focus-within {
  box-shadow: inset 0 0 0 var(--ctr-editor-border-width-focused, 2px) var(--ctr-editor-border-color-focused, $primary);
}

input.spg-input:focus:read-only {
  box-shadow: inset 0 0 0 var(--ctr-editor-border-width, 1px) var(--ctr-editor-border-color, $border-inside);
}

.spg-input:disabled,
.spg-input:disabled::placeholder,
input.spg-input:read-only,
input.spg-input:read-only::placeholder {
  color: color-mix(in srgb, var(--ctr-editor-content-text-color-disabled, $foreground-dim), transparent calc(100% - 100%*var(--ctr-editor-content-text-opacity-disabled, 0.25)));
  background-color: var(--ctr-editor-background-color-disabled, $background-dim);
}

.spg-input[type="color"] {
  padding-left: 0;
  padding-right: 0;
  min-width: calcSize(8);
}

.spg-input__edit-button {
  position: relative;
  box-sizing: border-box;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  border-radius: var(--ctr-editor-button-corner-radius);
  padding: var(--ctr-editor-button-padding-top, calcSize(1)) var(--ctr-editor-button-padding-right, calcSize(1))
    var(--ctr-editor-button-padding-bottom, calcSize(1)) var(--ctr-editor-button-padding-left, calcSize(1));
  line-height: 0;
  transition: background $creator-transition-duration, opacity $creator-transition-duration;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:hover {
    background: var(--ctr-editor-button-background-color-hovered, $background-dim);
  }

  &:active {
    opacity: var(--ctr-editor-button-icon-opacity-pressed, 0.5);
  }

  svg {
    width: var(--ctr-editor-button-icon-width, 24px);
    height: var(--ctr-editor-button-icon-height, 24px);
    fill: var(--ctr-editor-button-icon-color, $foreground-dim-light);
  }
}

.spg-input-container--multiline .spg-input__edit-button {
  align-self: flex-end;
}

.spg-input__edit-button--disabled,
.spg-input__edit-button:disabled {
  opacity: var(--ctr-editor-button-icon-opacity-disabled, 0.25);
  background: transparent;

  svg {
    fill: var(--ctr-editor-button-icon-color-disabled, $foreground-dim-light);
  }
}

.spg-input.spg-input--error {
  box-shadow: 0 0 0 1px inset var(--ctr-editor-border-color-error, $red);
}

.spg-input-container {
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: var(--ctr-editor-padding-top, calcSize(0.5)) var(--ctr-editor-padding-right, calcSize(0.5))
    var(--ctr-editor-padding-bottom, calcSize(0.5)) var(--ctr-editor-padding-left, calcSize(0.5));
  align-items: center;
  gap: var(--ctr-editor-gap, calcSize(0.5));
  box-shadow: inset 0 0 0 var(--ctr-editor-border-width, 1px) var(--ctr-editor-border-color, $border-inside);
}

.spg-input-container--multiline {
  align-items: end;
  flex-direction: column;
  gap: 0;
  height: auto;

  sv-ng-question-comment {
    display: flex;
  }

  .spg-input-container__input {
    box-sizing: border-box;
    resize: none;
  }
}

.spg-input-container__input {
  @include ctrDefaultFont;
  flex-grow: 1;
  width: 100%;
  padding: var(--ctr-editor-content-margin-top, calcSize(1)) var(--ctr-editor-content-margin-right, calcSize(1.5))
    var(--ctr-editor-content-margin-bottom, calcSize(1)) var(--ctr-editor-content-margin-left, calcSize(1.5));

  color: var(--ctr-editor-content-text-color, $foreground);
  outline: none;
  border: none;
  background-color: transparent;

  &::placeholder {
    color: var(--ctr-editor-content-text-color-placeholder, $foreground-dim-light);
  }
}

.spg-input-container__input:disabled,
.spg-input-container__input:disabled::placeholder {
  opacity: var(--ctr-editor-content-text-opacity-disabled, 0.25);
  color: var(--ctr-editor-content-text-color-disabled, $foreground-dim);
  background-color: var(--ctr-editor-background-color-disabled, $background-dim);
}

.spg-input-container__buttons-container {
  display: flex;
  gap: var(--ctr-editor-buttons-gap, calcSize(0.5));
  align-self: stretch;
}
