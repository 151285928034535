@import "../../variables.scss";

.svc-toolbox {
  --dft-toolbox-item-padding-top: calc(1 * #{$base-unit});
  --dft-toolbox-group-padding-right: calc(2 * #{$base-unit});
  --dft-toolbox-item-padding-right: calc(1.5 * #{$base-unit});
  --dft-toolbox-gap: calc(0.5 * #{$base-unit});
  --dft-toolbox-width-compact: calc(9 *#{$base-unit});

  --thm-toolbox-width-compact: calc(
    var(--ctr-toolbox-item-icon-width) +
    var(--ctr-toolbox-item-padding-right-no-text) +
    var(--ctr-toolbox-item-padding-left-no-text) +
    var(--ctr-toolbox-item-margin-horizontal-no-text) * 2 +
    var(--ctr-toolbox-group-padding-right) +
    var(--ctr-toolbox-group-padding-left)
  );

  --thm-toolbox-item-banner-left: calc(
    var(--ctr-toolbox-item-icon-width) +
    var(--ctr-toolbox-item-padding-right-no-text) +
    var(--ctr-toolbox-item-padding-left-no-text) +
    var(--ctr-toolbox-item-margin-horizontal-no-text) * 2 +
    var(--ctr-toolbox-group-padding-right) +
    var(--ctr-tooltip-pointer-height) * 2
  );

  --toolbox-width: var(--ctr-toolbox-min-width, 224.984375px /*calcSize(28)*/);
  --toolbox-width-compact: var(--thm-toolbox-width-compact, var(--dft-toolbox-width-compact));
  
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.svc-toolbox__panel {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  border-inline-end: var(--ctr-toolbox-border-width-right, 0px) solid var(--ctr-toolbox-border-color, transparent);
  background: var(--ctr-toolbox-background-color, $background-dim);
  min-width: var(--toolbox-width);
}

.svc-toolbox {

  .svc-scroll__scroller {
    padding: var(--ctr-toolbox-padding-top, calcSize(1.5)) var(--ctr-toolbox-group-padding-right, calcSize(1.5))
      var(--ctr-toolbox-padding-bottom, calcSize(2)) var(--ctr-toolbox-group-padding-left, calcSize(1.5));
    align-items: var(--ctr-toolbox-scroller-align-items, flex-end);

  }
  .svc-scroll__scrollbar {
    inset-inline-end: var(--ctr-toolbox-scrollbar-right, initial);
    inset-inline-start: var(--ctr-toolbox-scrollbar-left, 0);
    padding: var(--ctr-toolbox-padding-top, calcSize(1)) 0 var(--ctr-toolbox-padding-bottom, calcSize(2)) 0;
  }
}

.svc-toolbox--scrollable {
  --toolbox-width: var(--ctr-toolbox-min-width, 236.984375px);
}
.svc-toolbox--compact {
  margin-inline-end: var(--ctr-toolbox-margin-left-compact, calcSize(1.5));
  .svc-toolbox__panel {
    overflow: visible;
    width: var(--toolbox-width-compact);
    min-width: var(--toolbox-width-compact);
  }
  .svc-scroll__scroller {
    width: 100vw;
    padding: var(--ctr-toolbox-padding-top, calcSize(1.5)) var(--ctr-toolbox-group-padding-right-compact, calcSize(1.5))
      var(--ctr-toolbox-padding-bottom, calcSize(2)) var(--ctr-toolbox-group-padding-left-compact, calcSize(1.5));
    pointer-events: none;
  }
  .svc-search {
    opacity: 0;
    position: absolute;
  }
  .svc-toolbox__search-container {
    padding-top: var(--ctr-toolbox-padding-top, calcSize(1.5));
    padding-inline-start: var(--ctr-toolbox-group-padding-left, calcSize(1.5));
    padding-inline-end: var(--ctr-toolbox-group-padding-left, calcSize(1.5));
    padding-bottom: calc(var(--ctr-toolbox-separator-padding-top, 8px) + var(--ctr-toolbox-gap, 4px));
  }
  .svc-toolbox__category-separator {
    box-sizing: content-box;
    padding-inline-end: var(--ctr-toolbox-separator-padding-right-compact, calcSize(2));
    padding-inline-start: var(--ctr-toolbox-separator-padding-left-compact, calcSize(2));
    margin-inline-end: calc(0px - var(--ctr-toolbox-group-padding-right-compact, 12px) - var(--ctr-toolbox-item-margin-horizontal-no-text, 4px));
    margin-inline-start: calc(0px - var(--ctr-toolbox-group-padding-left-compact, 12px) - var(--ctr-toolbox-item-margin-horizontal-no-text, 4px));
  }
  .svc-toolbox__category-separator--search {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }
}

.svc-toolbox__tool.svc-toolbox__search-button {
  z-index: 20;
  justify-content: center;
  align-items: center;
  width: fit-content;
  svg {
    height: var(--ctr-toolbox-item-icon-height, calcSize(3));
    width: var(--ctr-toolbox-item-icon-width, calcSize(3));
  }
}

.svc-toolbox--flyout {
  --toolbox-width: var(--ctr-toolbox-min-width, calc(32 *#{$base-unit}));
  margin-inline-end: var(--ctr-toolbox-margin-left-compact, calcSize(1.5));
  overflow: visible;
  overflow: visible;
  width: var(--toolbox-width-compact);
  max-width: var(--thm-toolbox-width-compact, calcSize(10.5));
  overflow: visible;
  border-inline-end: var(--ctr-toolbox-border-width-right, 0px) solid transparent;

  .svc-search {
    opacity: 1;
    position: relative;
  }

  .svc-toolbox__panel {
    z-index: 200;
    width: fit-content;
    position: relative;
    box-shadow:
      var(--ctr-toolbox-shadow-floating-2-offset-x, 0px) var(--ctr-toolbox-shadow-floating-2-offset-y, 8px)
        var(--ctr-toolbox-shadow-floating-2-blur, 16px) var(--ctr-toolbox-shadow-floating-2-spread, 0px)
        var(--ctr-toolbox-shadow-floating-2-color, rgba(0, 0, 0, 0.1)),
      var(--ctr-toolbox-shadow-floating-1-offset-x, 0px) var(--ctr-toolbox-shadow-floating-1-offset-y, 2px)
        var(--ctr-toolbox-shadow-floating-1-blur, 6px) var(--ctr-toolbox-shadow-floating-1-spread, 0px)
        var(--ctr-toolbox-shadow-floating-1-color, rgba(0, 0, 0, 0.1));
    transition: box-shadow $creator-transition-duration;
  }
}
.svc-toolbox--searchable {
  .svc-toolbox__search-container {
    display: flex;
  }
}
.svc-toolbox__search-container {
  position: sticky;
  top: 0;
  display: none;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--ctr-toolbox-background-color, $background-dim);
  border-bottom: var(--ctr-toolbox-search-border-width-bottom, 1px) solid transparent;

  svg {
    pointer-events: none;
  }
}

.svc-toolbox__category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.svc-toolbox__category-separator {
  box-sizing: border-box;
  padding-top: var(--ctr-toolbox-separator-padding-top, calcSize(1.5));
  padding-inline-end: var(--ctr-toolbox-separator-padding-right, calcSize(2));
  padding-bottom: var(--ctr-toolbox-separator-padding-bottom, calcSize(1));
  padding-inline-start: var(--ctr-toolbox-separator-padding-left, calcSize(2));
  margin-top: var(--ctr-toolbox-gap, 0);
  margin-inline-end: calc(0px - var(--ctr-toolbox-group-padding-right, 12px));
  margin-inline-start: calc(0px - var(--ctr-toolbox-group-padding-left, 12px));
  max-width: var(--ctr-toolbox-separator-max-width, calcSize(9));
  &:after {
    content: "";
    display: block;
    height: var(--ctr-toolbox-separator-height, 1px);
    background-color: var(--ctr-toolbox-separator-color, $border);
  }
}

.svc-toolbox__category-separator--search {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-top: 0;
  max-width: initial;
}

.svc-toolbox--no-separators {
  .svc-toolbox__category-separator {
    display: none;
  }
  .svc-toolbox__category-separator--search {
    display: block;
  }
}

.svc-toolbox__category-header-wrapper {
  padding: var(--ctr-toolbox-group-header-margin-top, 0px) var(--ctr-toolbox-group-header-margin-left, 0px)
    var(--ctr-toolbox-group-header-margin-bottom, 0px) var(--ctr-toolbox-group-header-margin-left, 0px);
  margin-inline-start: calc(0px - var(--ctr-toolbox-group-padding-left, 0px));
  margin-inline-end: calc(0px - var(--ctr-toolbox-group-padding-right, 0px));
  align-self: stretch;
}
.svc-toolbox__category-header {
  padding-top: var(--ctr-toolbox-group-header-padding-top, calcSize(1.5));
  padding-inline-end: var(--ctr-toolbox-group-header-padding-right, calcSize(1.5));
  padding-bottom: var(--ctr-toolbox-group-header-padding-bottom, calcSize(1.5));
  padding-inline-start: var(--ctr-toolbox-group-header-padding-left, calcSize(1.5));

  box-shadow: 
    inset 0 var(--ctr-toolbox-group-header-border-width-top, 0px) var(--ctr-toolbox-group-header-border-color, $border),
    inset calc(0px - var(--ctr-toolbox-group-header-border-width-right, 0px)) 0 var(--ctr-toolbox-group-header-border-color, $border),
    inset 0 calc(0px - var(--ctr-toolbox-group-header-border-width-bottom, 1px)) var(--ctr-toolbox-group-header-border-color, $border),
    inset var(--ctr-toolbox-group-header-border-width-left, 0px) 0 var(--ctr-toolbox-group-header-border-color, $border);

  box-sizing: border-box;
  width: 100%;
  min-width: calcSize(20);
  position: relative;
  display: flex;
}

.svc-toolbox__category-header--collapsed {
  cursor: pointer;
}

.svc-toolbox__category-title {
  @include ctrSmallBoldFont;
  color: $foreground;
  vertical-align: middle;
  display: block;
  flex-grow: 1;
}

.svc-toolbox__category-header__controls {
  inset-inline-end: calcSize(1);
  top: var(--ctr-category-header-controls-top, calcSize(1));
  display: none;
  align-self: center;
  margin-top: -50%;
  margin-bottom: -50%;
}

.svc-toolbox__category-header__button {
  fill: $foreground-light;
  height: var(--ctr-toolbox-item-icon-height, calcSize(3));
  width: var(--ctr-toolbox-item-icon-width, calcSize(3));
}

.svc-toolbox__category-header:hover {
  .svc-toolbox__category-header__controls {
    display: flex;
  }
}

.svc-toolbox__category--collapsed {
  .svc-toolbox__tool {
    height: 0;
    visibility: hidden;
  }
}

.svc-toolbox__category--empty {
  height: 0;
  visibility: hidden;
}

.svc-toolbox__placeholder {
  @include ctrSmallFont;
  padding-top: calcSize(13.5);
  margin-inline-start: auto;
  margin-inline-end: auto;
  color: var(--ctr-property-grid-placeholder-text-description-color, $foreground-light);
}
